import { CATEGORY_ID } from "./categories";

export const RESTAURANTS = [
    {
        name: "Central da Pizza",
        openHours: {
            "Seg": "Fechado",
            "Ter": "17:00-23:59h",
            "Qua": "17:00-23:59h",
            "Qui": "17:00-23:59h",
            "Sex": "17:00-23:59h",
            "Sab": "17:00-23:59h",
            "Dom": "17:00-23:59h"
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "central-da-pizza",
        orderLink: "https://wa.me/5599991216404?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Pizzaria Central Mix",
        openHours: {
            "Seg": "18:00-23:00h",
            "Ter": "18:00-23:00h",
            "Qua": "18:00-23:00h",
            "Qui": "18:00-23:00h",
            "Sex": "18:00-23:00h",
            "Sab": "18:00-23:00h",
            "Dom": "18:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "pizzaria-central-mix", 
        orderLink: "https://wa.me/5599991515550?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Facebar",
        openHours: {
            "Seg": "16:00h-23:00h",
            "Ter": "16:00h-23:00h",
            "Qua": "16:00h-23:00h",
            "Qui": "16:00h-23:00h",
            "Sex": "16:00h-23:00h",
            "Sab": "10:00-23:00h",
            "Dom": "10:00-23:00h"
        },
        categories: [
            CATEGORY_ID.PIZZA,
            CATEGORY_ID.DISH,
            CATEGORY_ID.HAMBURGUER,
            CATEGORY_ID.BARBECUE,
            CATEGORY_ID.PASTEL
        ],
        image: "facebar",
        orderLink: "https://wa.me/5599981360135?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Do Japa",
        openHours: {
            "Seg": "Fechado",
            "Ter": "19:00-22:30h",
            "Qua": "19:00-22:30h",
            "Qui": "19:00-22:30h",
            "Sex": "19:00-22:30h",
            "Sab": "19:00-22:30h",
            "Dom": "10:00-23:00h" 
        } ,
        openDays: "Ter, Qua, Qui, Sex, Sab - Dom",
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.ORIENTAL],
        image: "do-japa",
        orderLink: "https://wa.me/5599981806453?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Rota 138",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:30-23:30h",
            "Qua": "18:30-23:30h",
            "Qui": "18:30-23:30h",
            "Sex": "18:30-23:30h",
            "Sab": "18:30-23:30h",
            "Dom": "18:30-23:30h" 
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.PIZZA, CATEGORY_ID.HAMBURGUER],
        image: "rota-138", 
        orderLink: "https://wa.me/5599982008308?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Hora Burguer 1583",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:00-23:00h",
            "Qua": "18:00-23:00h",
            "Qui": "18:00-23:00h",
            "Sex": "18:00-23:00h",
            "Sab": "18:00-23:00h",
            "Dom": "18:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.HAMBURGUER],
        image: "hora-burguer-1583",
        orderLink: "https://wa.me/5599981792688?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Restaurante da Baixinha",
        openHours: {
            "Seg": "08:00-14:00h | 19:00-22:00h",
            "Ter": "08:00-14:00h | 19:00-22:00h",
            "Qua": "08:00-14:00h | 19:00-22:00h",
            "Qui": "08:00-14:00h | 19:00-22:00h",
            "Sex": "08:00-14:00h | 19:00-06:00h",
            "Sab": "08:00-14:00h | 19:00-06:00h",
            "Dom": "08:00-14:00h | 19:00-06:00h" 
        } ,
        categories: [CATEGORY_ID.DISH],
        image: "restaurante-da-baixinha",
        orderLink: "https://wa.me/559991432157?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Sushi House",
        openHours: {
            "Seg": "Fechado",
            "Ter": "17:00-23:00h",
            "Qua": "17:00-23:00h",
            "Qui": "17:00-23:00h",
            "Sex": "17:00-23:00h",
            "Sab": "17:00-23:00h",
            "Dom": "17:00-23:00h" 
        },
        categories: [CATEGORY_ID.ORIENTAL],
        image: "sushi-house",
        orderLink: "https://wa.me/5599991025387?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Saborear Estreito",
        openHours: {
            "Seg": "08:00-13:30h",
            "Ter": "08:00-13:30h",
            "Qua": "08:00-13:30h",
            "Qui": "08:00-14:00h",
            "Sex": "08:00-14:00h",
            "Sab": "08:00-14:00h",
            "Dom": "08:00-14:00h" 
        },
        categories: [CATEGORY_ID.DISH],
        image: "saborear-estreito",
        orderLink: "https://wa.me/5599984533854?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "ACASA Emporio",
        openHours: {
            "Seg": "06:30-22:00h",
            "Ter": "06:30-22:00h",
            "Qua": "06:30-22:00h",
            "Qui": "06:30-22:00h",
            "Sex": "06:30-22:00h",
            "Sab": "06:30-22:00h",
            "Dom": "Fechado" 
        } ,
        categories: [
            CATEGORY_ID.DISH,
            CATEGORY_ID.PASTEL,
            CATEGORY_ID.SNACK,
            CATEGORY_ID.TAPIOCA,
        ],
        image: "acasa-emporio",
        orderLink: "https://wa.me/559985567476?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Jow Pizzaria",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:30-23:00h",
            "Qua": "18:30-23:00h",
            "Qui": "18:30-23:00h",
            "Sex": "18:30-23:00h",
            "Sab": "18:30-23:30h",
            "Dom": "18:30-23:30h" 
        } ,
        categories: [
            CATEGORY_ID.PIZZA,
            CATEGORY_ID.BARBECUE,
            CATEGORY_ID.DISH,
            CATEGORY_ID.HAMBURGUER
        ],
        image: "jow-pizzaria",
        orderLink: "https://wa.me/5599991710813?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Cactos Lanches",
        openHours: {
            "Seg": "17:00-22:00h",
            "Ter": "17:00-22:00h",
            "Qua": "17:00-22:00h",
            "Qui": "17:00-22:00h",
            "Sex": "17:00-22:00h",
            "Sab": "17:00-22:00h",
            "Dom": "Fechado" 
        } ,
        categories: [CATEGORY_ID.HAMBURGUER],
        image: "cactos-lanches",
        orderLink: "https://wa.me/5599992067083?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Açaí Oca",
        openHours: {
            "Seg": "15:00-23:30h",
            "Ter": "15:00-23:30h",
            "Qua": "15:00-23:30h",
            "Qui": "15:00-23:30h",
            "Sex": "15:00-23:30h",
            "Sab": "15:00-23:30h",
            "Dom": "15:00-23:30h" 
        } ,
        categories: [
            CATEGORY_ID.ACAI,
            CATEGORY_ID.SNACK,
            CATEGORY_ID.TAPIOCA,
            CATEGORY_ID.ICE_CREAM,
            CATEGORY_ID.SHAWARMA
        ],
        image: "acai-oca",
        orderLink: "https://wa.me/5599991889594?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Premium Açaiteria",
        openHours: {
            "Seg": "14:00-22:00h",
            "Ter": "14:00-22:00h",
            "Qua": "14:00-22:00h",
            "Qui": "14:00-22:00h",
            "Sex": "14:00-22:00h",
            "Sab": "14:00-22:00h",
            "Dom": "14:00-22:00h" 
        } ,
        categories: [
            CATEGORY_ID.ACAI,
            CATEGORY_ID.SHAWARMA,
            CATEGORY_ID.TAPIOCA,
            CATEGORY_ID.SNACK
        ],
        image: "premium-acaiteria",
        orderLink: "https://wa.me/5599991198943?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Pizzaria do Nabor",
        openHours: {
            "Seg": "18:00-22:00h",
            "Ter": "18:00-22:00h",
            "Qua": "18:00-22:00h",
            "Qui": "18:00-22:00h",
            "Sex": "18:00-22:00h",
            "Sab": "Fechado",
            "Dom": "18:00-22:00h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "pizzaria-do-nabor",
        orderLink: "https://wa.me/5599982618289?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Shalom Pizza",
        openHours: {
            "Seg": "18:00-23:30h",
            "Ter": "18:00-23:30h",
            "Qua": "18:00-23:30h",
            "Qui": "18:00-23:30h",
            "Sex": "18:00-23:30h",
            "Sab": "18:00-23:30h",
            "Dom": "18:00-23:30h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "shalom-pizzaria",
        orderLink: "https://wa.me/5599984623848?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Delicia Burguer & Açaí",
        openHours: {
            "Seg": "16:00-23:00h",
            "Ter": "16:00-23:00h",
            "Qua": "16:00-23:00h",
            "Qui": "16:00-23:00h",
            "Sex": "16:00-23:00h",
            "Sab": "16:00-23:00h",
            "Dom": "16:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.ACAI, CATEGORY_ID.HAMBURGUER],
        image: "delicia-acai-burguer",
        orderLink: "https://wa.me/5599981382495?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "iCone Pizza",
        openHours: {
            "Seg": "19:00-22:00h",
            "Ter": "19:00-22:00h",
            "Qua": "19:00-22:00h",
            "Qui": "19:00-22:00h",
            "Sex": "19:00-22:00h",
            "Sab": "Fechado",
            "Dom": "Fechado" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "icone-pizza",
        orderLink: "https://wa.me/5599991069217?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Smash Burguer Delivery",
        openHours: {
            "Seg": "18:00-22:30h",
            "Ter": "18:00-22:30h",
            "Qua": "18:00-22:30h",
            "Qui": "18:00-22:30h",
            "Sex": "18:00-22:30h",
            "Sab": "18:00-22:30h",
            "Dom": "18:00-22:30h" 
        },
        categories: [CATEGORY_ID.HAMBURGUER],
        image: "smash-burguer",
        orderLink: "https://wa.me/5599981159642?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Delivery do Jean",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:30-23:30h",
            "Qua": "18:30-23:30h",
            "Qui": "18:30-23:30h",
            "Sex": "18:30-23:30h",
            "Sab": "18:30-23:30h",
            "Dom": "18:30-00:00h" 
        } ,
        categories: [
            CATEGORY_ID.HAMBURGUER,
            CATEGORY_ID.PIZZA, 
            CATEGORY_ID.SNACK
        ],
        image: "delivery-do-jean",
        orderLink: "https://wa.me/5599982603830?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Gomes Pizzaria",
        openHours: {
            "Seg": "18:00-23:00h",
            "Ter": "18:00-23:00h",
            "Qua": "18:00-23:00h",
            "Qui": "18:00-23:00h",
            "Sex": "18:00-23:00h",
            "Sab": "18:00-23:00h",
            "Dom": "18:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "gomes-pizzaria",
        orderLink: "https://wa.me/5599992047422?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Brasa Viva",
        openHours: {
            "Seg": "11:00-23:00h",
            "Ter": "11:00-23:00h",
            "Qua": "11:00-23:00h",
            "Qui": "11:00-23:00h",
            "Sex": "11:00-23:00h",
            "Sab": "11:00-23:00h",
            "Dom": "11:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.BARBECUE, CATEGORY_ID.DISH],
        image: "brasa-viva",
        orderLink: "https://wa.me/5599984224583?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    },
    {
        name: "Açaiteria da Tribo",
        openHours: {
            "Seg": "15:00-22:00h",
            "Ter": "15:00-22:00h",
            "Qua": "15:00-22:00h",
            "Qui": "15:00-22:00h",
            "Sex": "15:00-22:00h",
            "Sab": "15:00-22:00h",
            "Dom": "15:00-22:00h" 
        } ,
        categories: [CATEGORY_ID.ACAI],
        image: "acai-da-tribo",
        orderLink: "https://wa.me/5599981631852?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    },
    {
        name: "Churrascaria Fogão Caipira",
        openHours: {
            "Seg": "11:00-14:00h",
            "Ter": "11:00-14:00h",
            "Qua": "11:00-14:00h",
            "Qui": "11:00-14:00h",
            "Sex": "11:00-14:00h",
            "Sab": "11:00-14:00h",
            "Dom": "11:00-14:00h" 
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE],
        image: "fogao-caipira",
        orderLink: "https://wa.me/5599981791012?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    },
    {
        name: "Casa do Pastel",
        openHours: {
            "Seg": "Fechado",
            "Ter": "Fechado",
            "Qua": "18:30-22:30h",
            "Qui": "18:30-22:30h",
            "Sex": "18:30-22:30h",
            "Sab": "18:30-22:30h",
            "Dom": "18:30-22:30h"
        } ,
        categories: [CATEGORY_ID.PASTEL, CATEGORY_ID.ICE_CREAM],
        image: "casa-do-pastel",
        orderLink: "https://wa.me/5599985541994?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    }
]